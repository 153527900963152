import * as React from "react"
import * as styles from "./styles.scss"
import VisibilityToggler from "./VisibilityToggler"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import cx from "classnames"
import { OrderEditorAreaContext } from "../.."
import { GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_editor_help, GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors } from "schema"
import { useTranslation } from "react-i18next"
import ValidateErrorText from "components/ValidateAreaError"

const BaseRow:React.FC<BaseRowProps> = (props) =>  {
    const { label, onClick, isExpanded, disabled } = props
    const { t } = useTranslation("editOrder")
    return (
      <OrderEditorAreaContext.Consumer>
        {({ editor }) => (
          <div
            data-testid={editor.behaviour}
            className={cx(styles.row, {
              [styles.adstateView__row]: props.isFEUser,
              [styles.expanded]: isExpanded,
              [styles.disabled]: disabled,
            })}
          >
            <VisibilityToggler disabled={disabled} isFEUser={props.isFEUser} />

            <div
              className={cx(styles.clickableContainer, {
                [styles.disabled]: disabled,
              })}
              onClick={disabled ? undefined : onClick}
            >
              <span className={styles.title}>{label}</span>

              <ValidateErrorText validationError={props.validationError}/>
              <FontAwesomeIcon
                className={styles.caretIcon}
                icon="caret-down"
              />
            </div>

          </div>
        )}
      </OrderEditorAreaContext.Consumer>
    )

}

interface BaseRowProps {
  label: string
  isExpanded: boolean
  disabled?: boolean
  onClick: () => void
  validationError: GetOrderById_orders_mediaOrder_edit_editModelAdvert_basePage_areas_content_validateErrors[];
  isFEUser: boolean
}

export default BaseRow
