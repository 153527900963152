import React from 'react';
import * as styles from './styles.scss';
import { useQuery } from 'react-apollo-hooks';
import GET_NET_NOTICE from 'graphql/queries/GetNetNotice';
import { GetNetNotice, GetNetNoticeVariables } from '../../../../../schema';
import cx from 'classnames'
import Spinner from 'components/Spinner';
import Button from 'components/Button';
import ErrorMessage from 'components/ErrorMessage';


const NetNoticePreview: React.FC<{
    isNetNoticeVisible: boolean
    orderId: string
    updateButtonText: string
    isFEUser: boolean
}> = (props) => {
    const iframeRef = React.useRef<HTMLIFrameElement>(null)
    let htmlDoc = ''

    const { data, loading, error, refetch } = useQuery<GetNetNotice, GetNetNoticeVariables>(GET_NET_NOTICE, {
        variables: {
            id: props.orderId!,
            isNetNoticeVisible: props.isNetNoticeVisible
        }
    })

    if (!props.isNetNoticeVisible) {
        return null
    }

    if (error) {
        return <ErrorMessage message={error.message} />
    }

    if (data?.orders &&
        data.orders[0].mediaOrder &&
        data.orders[0].mediaOrder.preview.material &&
        data.orders[0].mediaOrder.preview.material.basePage &&
        data.orders[0].mediaOrder.preview.material.basePage.netnotice) {
        htmlDoc = `${data.orders[0].mediaOrder.preview.material.basePage.netnotice.html}`
    }

    const updateNetNoticeContent = () => {
        refetch()
    }

    return (
        <div className={cx(styles.container)}>
            {loading && (<Spinner />)}

            {data && (
                <React.Fragment>
                    <Button variant={props.isFEUser! ? "feView" : "secondary"} onClick={updateNetNoticeContent} isWorking={loading}>{props.updateButtonText}</Button>
                    <div className={styles.iframeContainer} >
                        <iframe
                            ref={iframeRef}
                            className={styles.iframe}
                            srcDoc={htmlDoc}
                            frameBorder="0"
                        />
                    </div>
                </React.Fragment>
            )}

        </div>
    )
};

export default NetNoticePreview;