import * as React from "react"
import * as styles from "./styles.scss"
import Symbols from "./Symbols"
import { useQuery } from "react-apollo-hooks"
import {
  GetGalleryImages_galleryImagesByTags_images,
  GetGalleryImageInformation_galleryImages,
  GetGalleryImageTagsByTagGroup,
  GetGalleryImageTagsByTagGroupVariables,
  EditModelAdvertAreaContentRepository,
} from "schema"
import FavoritesDropdown from "./FavoritesDropdown"
import CheckboxField from "components/CheckboxField"
import { DropdownOption } from "components/DropdownButton"
import AddToFavoriteModal from "./AddToFavoriteModal"
import TagSelect from "./TagSelect"
import SelectedFavoriteRow from "./SelectedFavoriteRow"
import { useTranslation } from "react-i18next"
import { AppContext } from "App"
import GET_GALLERY_IMAGE_TAGS_BY_TAG_GROUPS from "graphql/queries/getGalleryImageTagsByTagGroup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  Custom_GetGalleryImages_galleryImagesByTags_images,
  IPaginationsOptions,
} from "customSchema.interface"
import Button from "components/Button"
import produce from "immer"
import Pagination from "components/Pagination"
import { PdfViewer } from "./PdfViewer"
import cx from "classnames"

const SymbolSearchColumn: React.FC<{
  symbols: GetGalleryImages_galleryImagesByTags_images[] | any
  selectedSymbol: GetGalleryImageInformation_galleryImages
  isLoading: boolean
  tags: DropdownOption[]
  searchOnlyColors: boolean
  handleToggleOnlyColors: () => void
  onSave: (id: string) => void
  onTagUpdate: (
    tagsToSearchBy: DropdownOption[],
    tagsInSelect: DropdownOption[]
  ) => void
  onFetchSymbolInfo: (
    symbolId: string,
    preventSelect?: boolean,
    symbol?: any
  ) => void
  setPaginationsOptions: (arg: any) => void
  paginationsOptions: IPaginationsOptions
  printMutation: any
  templateAreaId: string
  documentFormatId: string
  repository?: EditModelAdvertAreaContentRepository | null
  mediaId: string | null | undefined
  isFEUser: boolean
}> = (props) => {
  const [t] = useTranslation("editOrder")
  // const { TEMPLATE, GALLERY, ORDER } = EditModelAdvertAreaContentRepository
  const isGalleryModal = true

  const [
    selectedFavoriteTagState,
    setSelectedFavoriteTagState,
  ] = React.useState<any>(undefined)
  const [
    addToFavoritesModalIsOpen,
    setAddToFavoritesModalIsOpen,
  ] = React.useState<boolean>(false)
  const [onlyColor, setOnlyColor] = React.useState<boolean>(
    props.searchOnlyColors
  )
  const [symbolsToAddToFavorite, setSymbolsToAddToFavorite] = React.useState<
    GetGalleryImages_galleryImagesByTags_images[]
  >([])

  const [selectedFavoritLength, setSelectedFavoritLength] = React.useState(0)
  const { currentUserSession } = React.useContext(AppContext)
  const [currentPage, setCurrentPage] = React.useState(1)
  const [printLoading, setPrintLoading] = React.useState<boolean>(false)

  const { loading, data, refetch } = useQuery<
    GetGalleryImageTagsByTagGroup,
    GetGalleryImageTagsByTagGroupVariables
  >(GET_GALLERY_IMAGE_TAGS_BY_TAG_GROUPS, {
    variables: {
      input: {
        tagGroupIds: [currentUserSession.customer!.office.company.id],
        documentFormatId: props.documentFormatId,
        templateAreaId: props.templateAreaId,
      },
    },
  })

  const companyTags =
    loading || !data!.galleryImagesTagsByTagGroups[0]
      ? []
      : data!.galleryImagesTagsByTagGroups[0].tags

  React.useEffect(() => {
    if (companyTags)
      setSelectedFavoriteTag({ label: companyTags[0], value: companyTags[0] })
  }, [data])

  const setSelectedFavoriteTag = (option: DropdownOption) => {
    option.value ? props.onTagUpdate([option], []) : props.onTagUpdate([], [])
    setSelectedFavoriteTagState(option.value)
  }

  const handleColorCheckboxChange = () => {
    setOnlyColor(!onlyColor)
    props.handleToggleOnlyColors()
    // pagination need to be reset when tags changing
    ResetPagination()
  }

  const openAddToFavoritesModal = (
    symbol?: GetGalleryImages_galleryImagesByTags_images
  ) => {
    // setAddToFavoritesModalIsOpen(true)
    const favoritList: any = []
    props.symbols.map((item: any) => {
      if (item.isSelected === true) {
        favoritList.push(item)
      }
    })
    setSelectedFavoritLength(favoritList.length)
    symbol ? addSingleSymbol(favoritList) : addAllSymbolsFromSearchResult()
  }

  const closeAddToFavoritesModal = () => setAddToFavoritesModalIsOpen(false)

  const OpenFavoritModal = () => setAddToFavoritesModalIsOpen(true)

  const addSingleSymbol = (
    symbol: Custom_GetGalleryImages_galleryImagesByTags_images
  ) => {
    setSymbolsToAddToFavorite([symbol])
  }

  const handleSelectTagUpdate = (tags: DropdownOption[]) => {
    props.onTagUpdate(tags, tags)
    setSelectedFavoriteTagState(undefined)
    // pagination need to be reset when tags changing
    ResetPagination()
  }

  const addAllSymbolsFromSearchResult = () =>
    setSymbolsToAddToFavorite(props.symbols)

  const handlePrint = () => {
    setPrintLoading(true)
    props
      .printMutation()
      .then((res: any) => {
        const url = res.data.printGalleryImagesByTags.publicUrl
        if (url) {
          let link = document.createElement("a")
          link.href = url
          link.download = "Taps5"
          link.target = "_blank"
          link.dispatchEvent(new MouseEvent("click"))
          setPrintLoading(false)
        }
      })
      .catch((error: any) => console.log(error))
    // window.print()
  }

  const ResetPagination = () => {
    props.setPaginationsOptions(
      produce(props.paginationsOptions, (draft) => {
        draft.startIndex = 0
      })
    )
    setCurrentPage(1)
  }

  return (
    <>
      <div className={styles.symbolSearchColumn}>
        {isGalleryModal && (
          <>
            <div className={styles.searchContainer}>
              <TagSelect
                onlyColor={onlyColor}
                tags={props.tags}
                onTagUpdate={handleSelectTagUpdate}
                documentFormatId={props.documentFormatId}
                templateAreaId={props.templateAreaId}
                mediaId={props.mediaId}
                isFEUser={props.isFEUser}
              />
              <FavoritesDropdown
                isSymbolModal={true}
                onOpenAddToFavoritesModal={openAddToFavoritesModal}
                companyTags={companyTags}
                onSetSelectedFavoriteTag={setSelectedFavoriteTag}
                selectedFavoriteTag={selectedFavoriteTagState}
                saveSearchResultDisabled={
                  !props.symbols.length || !!selectedFavoriteTagState
                }
                isFEUser={props.isFEUser}
              />
            </div>

            {props.symbols.length > 0 && (
              <div className={styles.level2buttons}>
                <CheckboxField
                  checked={onlyColor}
                  onChange={handleColorCheckboxChange}
                  label={t("onlyColorSymbols")}
                  className={styles.colorCheckbox}
                />
                {props.isFEUser ? (
                  <Button
                    className={styles.feView__printbtn}
                    onClick={handlePrint}
                    variant={"feView"}
                    isWorking={printLoading}
                  >
                    {t("symbolOverview")}
                  </Button>
                ) : (
                  <Button
                    onClick={handlePrint}
                    className={styles.printbtn}
                    color="secondary"
                    icon="print"
                    isWorking={printLoading}
                  >
                    {t("print")}
                  </Button>
                )}
              </div>
            )}
            {selectedFavoritLength > 0 && (
              <div
                className={cx(styles.favoritModalBtn, {
                  [styles.FEView__favoritModalBtn]: props.isFEUser,
                })}
                onClick={OpenFavoritModal}
              >
                <FontAwesomeIcon icon={"plus-circle"} />
                <span>{t("saveSelectedIconToTheList")}</span>
              </div>
            )}
            {selectedFavoriteTagState !== undefined &&
              selectedFavoriteTagState !== null &&
              props.selectedSymbol !== null &&
              props.selectedSymbol.id !== null && (
                <SelectedFavoriteRow
                  tagName={selectedFavoriteTagState!}
                  onRefetchCompanyTags={refetch}
                  onSetSelectedFavoriteTag={setSelectedFavoriteTag}
                  onFetchSymbolInfo={props.onFetchSymbolInfo}
                  selectedSymbolId={props.selectedSymbol.id!}
                />
              )}
          </>
        )}
        <Symbols
          symbols={props.symbols}
          selectedSymbol={props.selectedSymbol}
          isLoading={props.isLoading}
          selectedFavoriteTag={selectedFavoriteTagState}
          onSave={props.onSave}
          onOpenAddToFavoritesModal={openAddToFavoritesModal}
          onFetchSymbolInfo={props.onFetchSymbolInfo}
          isSymbolGallery={isGalleryModal}
          isFEUser={props.isFEUser}
        />
        {isGalleryModal && (
          <>
            <AddToFavoriteModal
              isOpen={addToFavoritesModalIsOpen}
              onClose={closeAddToFavoritesModal}
              companyTags={companyTags}
              symbolsToAdd={symbolsToAddToFavorite}
              selectedSymbol={props.selectedSymbol}
              onFetchSymbolInfo={props.onFetchSymbolInfo}
              isFEUser={props.isFEUser}
            />
            <div className={styles.filler}></div>
            {props.paginationsOptions.totalImageCount >
              props.symbols.length && (
              <Pagination
                className={"paginationContainer"}
                paginationsOptions={props.paginationsOptions}
                setPaginationsOptions={props.setPaginationsOptions}
                currentPage={currentPage}
                totalCount={props.paginationsOptions.totalImageCount!}
                pageSize={props.paginationsOptions.PAGE_LENGTH!}
                onPageChange={(page) => setCurrentPage(page)}
              />
            )}
          </>
        )}
      </div>

      <PdfViewer symboles={props.symbols} className={styles.pdfViewer} />
    </>
  )
}

export default SymbolSearchColumn
