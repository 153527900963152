import * as React from "react"
import Rectangle from "./Rectangle"
import { OrderEditorContext } from "./../../../OrderEditoryAdvert"
import { useContextSelector } from "use-context-selector"

const Rectangles: React.FC<{
  isFEUser: boolean
}> = (props) => {
  const {
    hoveredAreaId,
    setExpandedAreaId,
    previewImageRectangles,
  } = useContextSelector(OrderEditorContext, (s) => {
    return {
      hoveredAreaId: s.hoveredAreaId,
      setExpandedAreaId: s.setExpandedAreaId,
      previewImageRectangles: s.previewImageRectangles,
    }
  })

  return (
    <React.Fragment>
      {previewImageRectangles.map((rectangle) => (
        <Rectangle
          key={rectangle.id}
          rectangle={rectangle}
          isHovered={rectangle.id === hoveredAreaId}
          onClick={setExpandedAreaId}
          isFEUser={props.isFEUser}
        />
      ))}
    </React.Fragment>
  )
}

export default Rectangles
