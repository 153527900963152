import * as React from "react"
import {  Redirect } from "react-router-dom"
import { Login, LoginVariables } from "schema"
import { useMutation } from "react-apollo-hooks"
import LOGIN from "graphql/mutations/login"
import queryString from "query-string"
import { SessionStorageKeys } from "environmentUtils"


function AutoLoginTempSessionToken(props: any) {
  const [redirectUrl, redirectUrlSet] = React.useState<
    string | string[] | null | undefined
  >(undefined)

  const loginMutation = useMutation<Login, LoginVariables>(LOGIN)

  const params = props.match.params as any
  const token = params.token
  const search = queryString.parse(props.location.search)

  React.useEffect(() => {
    if (!token) {
      return
    }
    loginMutation({
      variables: { userCredentialInput: { tempSessionToken: token } },
    }).then((data) => {
      sessionStorage.setItem(
        SessionStorageKeys.AUTH_TOKEN,
        data.data?.loginByCredential.authorizationToken ?? ""
      )
      localStorage.setItem(
        SessionStorageKeys.AUTH_TOKEN,
        data.data?.loginByCredential.authorizationToken ?? ""
      )
        redirectUrlSet(search.redirect as string)
    })
  }, [token, search.host, search.redirect])


  return (
    <React.Fragment>
      {redirectUrl && <Redirect to={redirectUrl as string} />}
    </React.Fragment>
  )
}

export default AutoLoginTempSessionToken