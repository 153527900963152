import * as React from "react"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { useApolloClient, useMutation } from "react-apollo-hooks"
import SELECT_ORDER_INITIATOR_VIEW from "graphql/mutations/selectOrderInitiatorView"
import {
  OrderInitiatorSelectLayout,
  SelectOrderInitiatorView,
  SelectOrderInitiatorViewVariables,
  SelectOrderInitiatorView_selectOrderInitiatorView_orderInitiatorId,
} from "schema"
import { ErrorText } from "components/ErrorMessage"
import Spinner from "components/Spinner"
import { useTranslation } from "react-i18next"
import Button from "components/Button"
import produce from "immer"
import { Options } from "./Options"
import styled from "styled-components"
import { useHistory } from "react-router"
import queryString from "query-string"

interface IUrlParameters {
  action?: string
  c?: string | null
  d?: string | null
  dt?: string | null
  m?: string | null
  o?: string | null
  fo?: string | null
  r?: any
}

export const ModalContent: React.FC<{
  caseFolderId?: string
  documentId?: string
  orderId?: string
  shouldCreateOrder: boolean
  onCloseModal: (
    event: any,
    shouldRedirect: boolean,
    redirectUrl?: string
  ) => void
  onSelectOrderInitiatorView?: (
    value: SelectOrderInitiatorView_selectOrderInitiatorView_orderInitiatorId
  ) => void
  isFEViewEnabled?: boolean
}> = (props) => {
  const history = useHistory()
  const [t] = useTranslation(["common", "errorMessages"])
  const parsedQuery = queryString.parse(history.location.search)
  const urlParameters: IUrlParameters = parsedQuery
  const [query, setQuery] = React.useState<{
    data: SelectOrderInitiatorView | null
    loading: boolean
    error: string | undefined
  }>({
    data: null,
    loading: false,
    error: undefined,
  })
  const [selectors, setSelectors] = React.useState<{
    documentFormatInitiatorId: string | null
    createOrder: boolean
    sequence: string[]
    index: number
  }>({
    documentFormatInitiatorId: null,
    createOrder: props.shouldCreateOrder,
    sequence: [""],
    index: 0,
  })

  const client = useApolloClient()
  const mutationSelectOrderView = useMutation<
    SelectOrderInitiatorView,
    SelectOrderInitiatorViewVariables
  >(SELECT_ORDER_INITIATOR_VIEW, {
    client,
    context: {},
  })

  const triggerMutation = (_selector: string) => {
    const parameterVariables = {
      caseFolderId: urlParameters.c ?? "",
      selector: _selector,
      documentId: urlParameters.d ?? null,
      createOrder: selectors.createOrder ?? null,
      orderId: urlParameters.o ?? null,
      fromOrderId: urlParameters.fo ?? null,
      mediaId: urlParameters.m ?? null,
      documentTypeId: urlParameters.dt ?? null,
      route: urlParameters.r ?? null,
    }

    const defaultVariable = {
      caseFolderId: props.caseFolderId ?? "",
      selector: _selector,
      documentId: props.documentId,
      createOrder: selectors.createOrder,
      orderId: null,
      fromOrderId: props.orderId,
    }
    setQuery(
      produce(query, (draft) => {
        draft.loading = true
        draft.error = undefined
      })
    )
    mutationSelectOrderView({
      variables: props.isFEViewEnabled
        ? parameterVariables
        : defaultVariable,
    })
      .then((response) => {
        if (response.errors) {
          setQuery(
            produce(query, (draft) => {
              draft.loading = false
              draft.error = response.errors?.map((e) => e.message).join(", ")
            })
          )
          return
        }
        if (!response.data) {
          setQuery(
            produce(query, (draft) => {
              draft.loading = false
              draft.error = t("errorMessages:unknowError")
            })
          )
          return
        }
        setQuery(
          produce(query, (draft) => {
            draft.loading = false
            if (
              response.data &&
              response.data.selectOrderInitiatorView &&
              response.data.selectOrderInitiatorView !== null
            ) {
              if (
                // is order created?
                response.data.selectOrderInitiatorView.order !== null &&
                response.data.selectOrderInitiatorView.order.id !== null
              ) {
                let redirectUrl =
                  response.data.selectOrderInitiatorView.order.client?.route
                    .editPageRoute.taps5RelUrl ?? undefined
                if (redirectUrl !== undefined) {
                  redirectUrl = redirectUrl.replace(/https?:\/\/[^\/]+/i, "")
                }
                draft.data = null
                props.onCloseModal({}, true, redirectUrl)
              }
              if (
                response.data.selectOrderInitiatorView.navigateRoute !== null
              ) {
                let redirectUrl =
                  response.data.selectOrderInitiatorView.navigateRoute
                if (redirectUrl !== undefined) {
                  console.log("navigateRoute triggered")
                  redirectUrl = redirectUrl.replace(/https?:\/\/[^\/]+/i, "")
                }
                // draft.data = null
                // props.onCloseModal({}, true, redirectUrl)
              }
              if (
                response.data.selectOrderInitiatorView.jsInvokeStatement !==
                null
              ) {
                const jsCode =
                  response.data.selectOrderInitiatorView.jsInvokeStatement
                const executeFn = new Function(jsCode)
                executeFn()
                console.log("jsInvokeStatement triggered")
                // draft.data = null
                // Could set draft.data = result.data, but it will flashing the screen. This feels better
                draft.data?.selectOrderInitiatorView.options.forEach((o, i) => {
                  o.isSelected =
                    response.data?.selectOrderInitiatorView?.options[i]
                      ?.isSelected ?? false
                })
              } else if (
                // should create order?
                response.data.selectOrderInitiatorView.orderInitiatorId &&
                response.data.selectOrderInitiatorView.orderInitiatorId !==
                  undefined &&
                response.data.selectOrderInitiatorView.orderInitiatorId !== null
              ) {
                if (props.onSelectOrderInitiatorView) {
                  props.onSelectOrderInitiatorView(
                    response.data.selectOrderInitiatorView.orderInitiatorId
                  )
                }
                props.onCloseModal({}, false, undefined)
                draft.data = response.data
              } else {
                // console.log("ModalContent::setData", response.data)
                draft.data = response.data
              }
            } else {
              draft.data = null
              draft.error = t("errorMessages:unknowError")
            }
          })
        )

        if (
          response.data &&
          response.data.selectOrderInitiatorView.orderInitiatorId
        ) {
          setSelectors(
            produce(selectors, (draft) => {
              if (
                response.data &&
                response.data.selectOrderInitiatorView.orderInitiatorId
              ) {
                // draft.createOrder = true
                // draft.documentFormatInitiatorId =
                //   response.data.selectOrderInitiatorView.orderInitiatorId.documentFormatInitiatorId
              }
            })
          )
          // triggerMutation(newSelectors.sequence[newSelectors.index])
        }
      })
      .catch((err) => {
        setQuery(
          produce(query, (draft) => {
            draft.loading = false
            draft.error = err.toString()
          })
        )
      })
  }

  const onSelectItem = (_selector: string) => {
    const newSelectors = produce(selectors, (draft) => {
      draft.sequence.push(_selector)
      draft.index = draft.index + 1
    })
    setSelectors(newSelectors)
    triggerMutation(newSelectors.sequence[newSelectors.index])
  }

  const onClickPrev = () => {
    triggerMutation(query.data!.selectOrderInitiatorView.previousSelector)
  
    // const newSelectors = produce(selectors, (draft) => {
    //   draft.sequence.pop()
    //   draft.index = draft.index - 1
    // })
    // setSelectors(newSelectors)
    // triggerMutation(newSelectors.sequence[newSelectors.index])
  }

  React.useEffect(() => {
    triggerMutation(selectors.sequence[selectors.index])
  }, [])
  return (
    <ModalContentStyles>
      <div className={"wrapper"}>
        {query.loading ? (
          <div className={"loading-wrapper"}>
            <Spinner />
          </div>
        ) : null}

        {query.data !== null ? (
          <>
            {(query.data!.selectOrderInitiatorView?.previousSelector?.length ??
              0) > 0 ? (
              <div className={"back-wrapper"}>
                <Button
                  variant={"tertiary"}
                  onClick={onClickPrev}
                  isWorking={query.loading}
                  icon={faArrowLeft}
                >
                  {t("common:back")}
                </Button>
              </div>
            ) : null}

            {query.data.selectOrderInitiatorView.header ? (
              <div className={"header-wrapper"}>
                {query.data.selectOrderInitiatorView.header}
              </div>
            ) : null}

            <Options
              subViews={query.data.selectOrderInitiatorView.subViews}
              options={query.data.selectOrderInitiatorView.options}
              layout={
                query.data.selectOrderInitiatorView.layout ??
                OrderInitiatorSelectLayout.IMAGE_TEXT_WITH_DROPDOWN_THUMBNAIL
              }
              caseFolderId={props.caseFolderId ?? ""}
              onCloseModal={props.onCloseModal}
              orderId={props.documentId}
              onSelectItem={onSelectItem}
              selectOrderInitiatorView={query.data.selectOrderInitiatorView}
              isFEViewEnabled={props.isFEViewEnabled!}
              selectType={query.data.selectOrderInitiatorView.selectType}
            />
          </>
        ) : null}

        {query.error ? (
          <div className={"error-wrapper"}>
            <ErrorText>{query.error}</ErrorText>
          </div>
        ) : null}
      </div>
    </ModalContentStyles>
  )
}

export const ModalContentStyles = styled.div`
  .wrapper {
    width: 100%;
    min-height: 350px;
    margin-top: 40px;
  }

  .loading-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.5);
    z-index: 10;
  }

  .back-wrapper {
    position: absolute;
    top: 19px;
    left: 15px;
  }

  .header-wrapper {
    text-align: center;
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 10px;
    margin-top: 0px;
    font-weight: 500;
  }

  .layout-IMAGE_TEXT_THUMBNAIL {
    max-height: 450px;
    overflow-y: auto;
    .items {
      padding: 0 25px;
      display: flex;
      flex-wrap: wrap;
    }
    .item {
      cursor: pointer;
      display: inline-block;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      /* padding-left: 8px;
      padding-right: 8px; */
      min-height: 300px;
      transition: box-shadow 1s ease-in-out;
      border: 1px solid #eee;
      margin: 15px;
      border-radius: 3px;
      &:hover {
        box-shadow: -1px 7px 5px 0px #c2c2c2;
        color: #1778e4;
      }

      img {
        min-height: 100px;
        max-height: 250px;
      }
    }
    &.layout-IMAGE_TEXT_THUMBNAIL__AdstateView {
      width: 100%;
      /* max-height: 750px !important; */
      .item {
        .loadingFiller {
          width: 150px;
          height: 300px;
          border-radius: 3px;
          /* background-color: #858585; */
          animation-duration: 1.7s;
          animation-fill-mode: forwards;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
          animation-name: pg;
          background: #c6c6c6; // Fallback
          background: linear-gradient(
            to right,
            #a9a9a9 2%,
            #ddd 18%,
            #a9a9a9 33%
          );
          background-size: 1300px;
        }
        @keyframes pg {
          0% {
            background-position: -650px 0;
          }
          100% {
            background-position: 650px 0;
          }
        }
        .name {
        }
      }
      .items {
        justify-content: center;
        .item .imageContainer {
          &.disableLoading {
            display: none;
          }
        }
        .item {
          border: 1px solid #e5e7eb;
          border-radius: 4px;
          padding: 5px;
          &.isItemSelected {
            outline-color: ${(props) => props.theme["--fe_secondary"]};
            outline-style: solid;
            outline-offset: 2px;
            border: 1px solid ${(props) => props.theme["--fe_secondary-disabled"]};
            background-color: #f9f6f1;
            border-radius: 4px;
            &:hover {
              border: 1px solid ${(props) => props.theme["--fe_secondary"]};
            }
          }
          &:hover {
            /* border: 2px solid #3C5E25;
            border-radius: 6px; */
            border: 1px solid ${(props) => props.theme["--fe_secondary-disabled"]};
            outline-color: ${(props) => props.theme["--fe_secondary"]};
            outline-style: solid;
            outline-offset: 2px;
            .name {
              color:  ${(props) => props.theme["--fe_secondary"]};
            }
          }

        }
      }
    }
  }

  .layout-IMAGE_TEXT_THUMBNAIL_WITH_SLIDER {
    .item {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      justify-content: center;
      padding-left: 8px;
      padding-right: 8px;
      min-height: 300px;
      margin-bottom: 16px;

      img {
        min-height: 250px;
        max-height: 250px;
      }
    }

    .dots-wrapper {
      column-gap: 5px;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        background: none;
      }

      .dot {
        height: 8px;
        width: 8px;
        background: ${(props) => props.theme["--secondary"]};
        border-radius: 8px;
      }
    }
  }

  .layout-MULTI_DROPDOWN_LINE {
    .items {
    }
  }

  .layout-TEXTBOX_LINE {
    .items {
      display: grid;
      column-gap: 23px;
      row-gap: 23px;
      grid-template-columns: 1fr 1fr 1fr;
      width: 100%;
      margin-top: 20px;
    }
    .item {
      border: 1px solid #dedede;
      border-radius: 3px;
      line-height: 42px;
      font-size: 15px;
      text-align: center;
      cursor: pointer;
    }
  }

  .layout-IMAGE_TEXT_WITH_DROPDOWN_THUMBNAIL {
    .items {
      display: grid;
      column-gap: 23px;
      row-gap: 23px;
      grid-template-columns: 1fr 1fr 1fr;
      width: 100%;
      margin-top: 20px;
    }

    .item {
      padding-top: 5px;
      padding-left: 0;
      padding-bottom: 5px;
      padding-right: 0;
      position: relative;
      cursor: pointer;
    }

    .item .bottom {
      position: relative;
    }

    .item:hover {
      .img {
        border: 2px solid #1778e4;
      }
      .title .value {
        color: #1778e4;
      }
      .title .arrow {
        color: #1778e4;
      }
    }

    .item .img {
      background: #ccc;
      min-height: 140px;
      min-width: 200px;
      width: 100%;
      height: auto;
      border: 2px solid #fff;
      transition: all 0.5s ease-in-out;
      box-shadow: 0px 4px 15px #00000014;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      max-height: 300px;
      max-width: 300px;
      overflow: hidden;
      margin: 0 auto;
    }

    .item .img img {
      width: 100%;
      height: 100%;
    }
    .item .title {
      width: 100%;
      padding-top: 21px;
      padding-bottom: 21px;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 10px;
    }
    .item .title .value {
      color: #161616;
      font-size: 25px;
      line-height: 30px;
      transition: all 0.5s ease-in-out;
    }
    .item .title.arrow {
      color: #1778e4;
    }
    .item .title-dropdown {
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 3px 6px #00000029;
      border-radius: 3px;
      position: absolute;
      top: 0;
      left: 50%;
      padding: 12px;
      transform: translate(-50%, 45px);
      z-index: 10;
    }
    .item .title-dropdown .options {
      /*max-height: 235px;*/
      overflow: auto;
      margin-bottom: -12px;
    }
    .item .title-dropdown .option {
      color: #161616;
      border: 1px solid #dedede;
      border-radius: 3px;
      margin-bottom: 16px;
      line-height: 42px;
      min-height: 42px;
      white-space: nowrap;
      min-width: 200px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.5s ease-in-out;
    }
    .item .title-dropdown .option:hover {
      border: 1px solid #1778e4;
    }
  }

  .item-loading {
    min-height: 349px;
  }

  .error-wrapper {
    text-align: center;
  }
`
