const LOCALHOST = "localhost";
const getEndpoint =  (protocol: string, secure: boolean): string | undefined => {
  const params = new URLSearchParams(window.location.search);
  let host = params.get('host');


  if (host) {
    sessionStorage.setItem('host', host);
  } else {
    host = sessionStorage.getItem('host');
  }

  const result = host ?? process.env.REACT_APP_TAPS5_BACKEND;
  
  if (!result) {
    console.error("REACT_APP_TAPS5_BACKEND is missing");
    return undefined;
  }

  const prefix = result.includes(LOCALHOST) ? protocol : `${protocol}${secure ? 's' : ''}`;
  return `${prefix}://${result}`;
};

export const getGQLEndpoint =  (): string | undefined=> {
  return getEndpoint('http', true);
};

export const getWSGQLEndpoint =  (): string | undefined => {
  return getEndpoint('ws', true);
};

export const SessionStorageKeys = {
  AUTH_TOKEN: "taps5_jwt_token",
  CLIENT_SESSION_ID: "taps5_client_session_id",
}

export const LocalStorageKeys = {
  SHOW_SUPPORT_VIEW: "taps5_showSupportView",
  SHOW_MODE_VIEW: "showModeView"
}


export const envKeys = {
  REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT
}