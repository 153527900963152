import gql from "graphql-tag"

const GET_NET_NOTICE = gql`
    query GetNetNotice($id: ID!, $isNetNoticeVisible: Boolean!) {
      orders(ids: [$id]) {
        id
        mediaOrder {
          preview {
            material {
              basePage {
                netnotice(visible: $isNetNoticeVisible) {
                  html
                  isError
                  errorReason
                }
              }
            }
          }
        }
      }
    }
`

export default GET_NET_NOTICE
