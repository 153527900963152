import gql from "graphql-tag"

const PREVIEW_IMAGE_FRAGMENT = gql`
  fragment PreviewImageFragment on MediaOrderPreview {
    material {
      basePage {
        size(unit: MILLIMETER) {
          width
          height
        }  
      }
      pages {
        png(resolution: 192, resolutionDeep:1.4) {
          url
        }
      }
    }
  }
`

export default PREVIEW_IMAGE_FRAGMENT
